import { Language } from '../../../types/Language';
export const DocumentUploadDisplayTextEn = {
    language: Language.ENGLISH,
    headerText: 'Upload documents for ',
    uploadCategory: 'To upload a document, first select a document type. You may upload up to 10 documents.',
    yourAbsenceEE: 'your absence',
    yourAbsenceER: 'your Employee\'s absence',
    submitText: 'Submit',
    cancelText: 'Cancel',
    closeText: 'Close',
    informationalMessage1: 'Please upload any available documentation to assist in processing ',
    informationalMessage1Life: 'Please submit two full years of Optional Life (if applicable) plus current benefit year and Beneficiary Designation. ' +
        'Please submit supporting documentation if the employee is on an approved leave that is not managed by Lincoln Financial Group.',
    informationalMessage2: 'When uploading documents, you are responsible for maintaining a copy of all documents provided to Lincoln Financial Group. A maximum of ten documents may be uploaded at a time. Supported file types that may be uploaded include: gif, jpg, pdf, pptx, tif, tiff, xls, xlsx, doc, docx.',
    informationalMessagePart: 'this specific event.',
    leave: 'Leave',
    claim: 'Claim',
    asEvent: 'Event',
    successMessage: 'documents were successfully submitted. Your documents will be reviewed by your specialist within 3 business days.  These documents will not be available for you to view on the portal.  You may now upload additional documents or exit the document upload tool.',
    failureMessage: 'There was an error trying to submit your documents, please try again. Trace Id: ',
    apiFailureMessage: "We're sorry, we were unable to submit your request at this time.  Please wait a moment and try again.  If the issue continues, please ",
    contactUs: "contact us",
    uploadSizeExceeded: 'Uploaded files cannot exceed 6 MB each.',
    uploadFileEmpty: 'Uploaded file cannot be empty. This document appears to be blank or empty.  Please make sure the document is larger than 0 MB.',
    uploadFilenameInvalid: 'The document that you are attempting to upload has invalid characters in the document name. Please remove invalid characters from the document name and try again. (Invalid character are: !@#\\$%^&*()+{}[];\':\\",/?<>)',
    uploadFileTypeInvalid: 'Accepted file types are as follows: gif, jpg, pdf, pptx, tif, tiff, xls, xlsx, doc, docx',
    delete: 'Delete',
    uploadButtonText: 'Upload Document',
    uploadLabel: 'Upload Documents',
    uploadLabels: 'Upload document'
};
