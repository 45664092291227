import useDentalDataContext from 'src/packages/dental/hooks/useDentalContext';
import useTranslate from 'src/hooks/useTranslate';
import { Alert, Link, Spinner } from '@digitools/honest-react';
import DentalText from 'src/packages/dental/constants/DentalText';
import React from 'react';
import { ErrorHtml } from 'src/packages/dental/Dental';
import ClaimBoxes from 'src/packages/dental/components/ClaimBoxes';
export const ClaimsInfo = () => {
    const { claimsLoading, currentMember, } = useDentalDataContext();
    const { t } = useTranslate();
    const hasError = currentMember?.claimSummaryError || currentMember?.hipaaViolation;
    const HipaaViolation = () => {
        const hipaaText = t(DentalText.hipaaViolationText).split('%%link%%');
        return (<p>
        {hipaaText[0]}
        <span>
          <Link target={'_blank'} href={'https://www.lincoln4benefits.com/adminWebContent/Production/Ardis_Forms/grup/BJ-03566.pdf'}>
            {t(DentalText.dentalInfoReleaseFormLinkText)}
          </Link>
        </span>
        {hipaaText[1]}
      </p>);
    };
    if (claimsLoading) {
        return <Spinner data-testid='claims-spinner'/>;
    }
    return (<>
      {currentMember?.hipaaViolation && (<Alert type={'info'}>
          <HipaaViolation />
        </Alert>)}
      {currentMember?.claimSummaryError && (<ErrorHtml errorType={currentMember.claimSummaryError} generalErrorMessage={`${t(DentalText.claimSummaryGeneralError)}`} notFoundErrorMessage={t(DentalText.claimSummaryNotFoundError)}/>)}

      {!hasError && currentMember?.claims && currentMember.claims.length == 0 && <Alert type={'info'}>{t(DentalText.claimsNotFoundInfo)}</Alert>}
      {!hasError && currentMember?.claims && currentMember?.claims?.length > 0 && <ClaimBoxes />}
    </>);
};
const Claims = () => {
    const { currentMember, subscriber } = useDentalDataContext();
    const { t } = useTranslate();
    return (<>
      {!!currentMember && !!subscriber && (<>
          <p>{t(DentalText.disclaimerText)}</p>
          {subscriber?.planOverview?.inNetworkOnlyClause && (<Alert style={{ padding: '.5rem' }} type={'info'}>
              {subscriber?.planOverview?.inNetworkOnlyClause}
            </Alert>)}
          <ClaimsInfo />
        </>)}
    </>);
};
export default Claims;
