import { Language } from '../../../types/Language';
export const DocumentUploadDisplayTextEs = {
    language: Language.SPANISH,
    headerText: 'Cargar documentos para el ',
    uploadCategory: 'Para cargar un documento, primero seleccione un tipo de documento. Puede cargar hasta 10 documentos.',
    yourAbsenceEE: 'su ausencia',
    yourAbsenceER: 'your Employee\'s absence',
    submitText: 'Enviar',
    cancelText: 'Cancelar',
    closeText: 'Cerrar',
    informationalMessage1: 'Cargue toda la documentación disponible para ayudar a procesar ',
    informationalMessage2: 'Al cargar documentos, usted es responsable de mantener una copia de todos los documentos proporcionados a Lincoln Financial Group. Se puede cargar un máximo de diez documentos a la vez. Los tipos de archivos admitidos que se pueden cargar incluyen: gif, jpg, pdf, pptx, tif, tiff, xls, xlsx, doc, docx.',
    informationalMessagePart: 'este evento específico.',
    leave: 'Leave',
    claim: 'Reclamo',
    asEvent: 'Evento',
    successMessage: 'documentos fueron enviados con éxito. Ahora puede cargar documentos adicionales o salir de la carga de documentos',
    failureMessage: 'Se produjo un error al intentar enviar sus documentos. Vuelva a intentarlo. Trace Id: ',
    uploadSizeExceeded: 'Los archivos cargados no pueden superar los 6 MB cada uno',
    uploadFileEmpty: 'Este documento parece estar en blanco o vacío. Por favor asegúrese de que el documento tenga más de 0 MB.',
    uploadFilenameInvalid: 'El documento que está intentando cargar tiene caracteres no válidos en el nombre del documento. Elimine los caracteres no válidos del nombre del documento y vuelva a intentarlo. (Los caracteres no válidos son: !@#\\$%^&*()+{}[];\':\\",/?<>)',
    uploadFileTypeInvalid: 'Los tipos de archivos aceptados son los siguientes: gif, jpg, pdf, pptx, tif, tiff, xls, xlsx, doc, docx',
    delete: 'Eliminar',
    uploadButtonText: 'Subir Documento',
    uploadLabel: 'Cargar Documentos',
    uploadLabels: 'Cargar documento',
    apiFailureMessage: 'Lo sentimos, no pudimos enviar su solicitud en este momento. Espere un momento y vuelva a intentarlo. Si el problema continúa, por favor ',
    contactUs: 'Contacta con nosotras',
};
