import React, { useState } from 'react';
import { Alert, Col, Container, Icon, Link, Loader, Row, SelectInput } from '@digitools/honest-react';
import { DocumentUploadDisplayTextEs } from '../documentUpload/language/DocumentUploadDisplayTextEs';
import { DocumentUploadDisplayTextEn } from './language/DocumentUploadDisplayTextEn';
import styled from 'styled-components';
import useAuthentication from 'src/hooks/useAuthentication';
import useExperience from 'src/hooks/useExperience';
import useTealium from 'src/hooks/useTealium';
import { TEALIUM_EVENT } from 'src/constants/tealiumConstants';
import { CustomEmployeeText } from 'src/utils/CustomExperienceText';
import { Experience } from 'src/types/Experience';
import UploadButton from 'src/components/documentUpload/UploadButton';
import { CategoryCol, StyledSerial } from 'src/components/documentUpload/CategorizationDocumentUpload';
import { docMeta } from 'src/packages/status/components/pages/ClaimDetails/docCategoryMapper';
import SelectCategory from 'src/components/documentUpload/SelectCategory';
import useTranslate from 'src/hooks/useTranslate';
import useCliCustomerConfigContext from 'src/packages/cli/hooks/useCliContext';
import { Language } from 'src/types/Language';
import { supportedFiles } from 'src/components/documentUpload/UploadConstants';
import { apiUploadDocument } from 'src/api/DocumentServiceApi';
// came from CLI
const StyledCol = styled(Col) `
  padding: 12px;
`;
// from CLI
const StyledLink = styled(Link) `
  font-weight: bold;
`;
const InlineDocumentUpload = ({ productName, documents, setDocuments }) => {
    const { language, experience } = useExperience();
    const { trackEvent } = useTealium();
    const { authHeader, user } = useAuthentication();
    const languageFile = language === Language.SPANISH ? DocumentUploadDisplayTextEs : DocumentUploadDisplayTextEn;
    const [errorMessage, setErrorMessage] = useState('');
    const [uploading, setUploading] = useState(false);
    const [documentCategory, setDocumentCategory] = useState('');
    const { t } = useTranslate();
    // cli specific
    const { isClaimLeaveCustomer } = useCliCustomerConfigContext();
    const [apiFailureMessage, setApiFailureMessage] = useState(false);
    //determine if it should see Claim categories or Coordinated categories For CLI
    const isCoordinatedClaim = !!(productName === 'STD' && isClaimLeaveCustomer);
    const tealiumEventType = (productName === 'LIFE') ? TEALIUM_EVENT.EVENT_TYPE.LIFE_CLAIM_INTAKE :
        TEALIUM_EVENT.EVENT_TYPE.CLAIM_LEAVE_INTAKE;
    let categoryList = docMeta.s1.noCategory;
    if (productName === 'LEAVE') {
        categoryList = docMeta.s1.leaves;
    }
    else if (isCoordinatedClaim) {
        categoryList = (experience === Experience.EMPLOYEE) ? docMeta.s1.coordinatedEE : docMeta.s1.coordinatedER;
    }
    else if (productName === 'STD') {
        if (experience === Experience.EMPLOYEE) {
            categoryList = (user?.customerInContext?.smallMid) ? docMeta.docsNet.claimsEE : docMeta.s1.claimsEE;
        }
        else {
            categoryList = docMeta.s1.claimsER;
        }
    }
    // TODO: move to own document util file?
    const handleFileUpload = async (event) => {
        setErrorMessage('');
        setApiFailureMessage(false);
        if (event.target.files.length >= 1) {
            const selectedFile = event.target.files[0];
            const fileName = selectedFile.name;
            if (fileName.match(/^[a-zA-Z0-9_.\s-]*$/)) {
                const fileType = fileName.substring(fileName.lastIndexOf('.') + 1, fileName.length);
                if (supportedFiles.indexOf(fileType.toLocaleLowerCase()) >= 0) {
                    if (selectedFile.size <= 6291456 && selectedFile.size !== 0) {
                        await uploadDocument(selectedFile);
                        event.target.value = null;
                    }
                    else if (selectedFile.size === 0) {
                        setErrorMessage(languageFile.uploadFileEmpty);
                    }
                    else {
                        setErrorMessage(languageFile.uploadSizeExceeded);
                        trackEvent({
                            event_action: TEALIUM_EVENT.EVENT_ACTION.ERROR,
                            event_type: tealiumEventType,
                            event_name: languageFile.uploadSizeExceeded,
                            event_version: 'upload document',
                        });
                    }
                }
                else {
                    setErrorMessage(languageFile.uploadFileTypeInvalid);
                    trackEvent({
                        event_action: TEALIUM_EVENT.EVENT_ACTION.ERROR,
                        event_type: tealiumEventType,
                        event_name: languageFile.uploadFileTypeInvalid,
                        event_version: 'upload document',
                    });
                }
            }
            else {
                setErrorMessage(languageFile.uploadFilenameInvalid);
                trackEvent({
                    event_action: TEALIUM_EVENT.EVENT_ACTION.ERROR,
                    event_type: tealiumEventType,
                    event_name: languageFile.uploadFilenameInvalid,
                    event_version: 'upload document',
                });
            }
        }
    };
    const uploadDocument = async (file) => {
        try {
            setUploading(true);
            const response = await apiUploadDocument(getFormData(file), authHeader());
            const docMeta = getDocMeta(documentCategory);
            setDocuments([
                ...documents,
                {
                    documentName: file.name,
                    docId: response.data,
                    label: docMeta.label,
                    documentTypeTitle: docMeta.title,
                    documentType: docMeta.type,
                },
            ]);
            setDocumentCategory('');
            trackEvent({
                event_action: TEALIUM_EVENT.EVENT_ACTION.UPLOAD,
                event_type: tealiumEventType,
                event_name: 'open',
                event_version: 'review',
                event_results: '1', //This will always be 1, you only can 'upload' one at a time, even if submitting ends up attaching all docs to one claim
            });
            setUploading(false);
        }
        catch (error) {
            setUploading(false);
            setDocumentCategory('');
            setApiFailureMessage(true);
            trackEvent({
                event_action: TEALIUM_EVENT.EVENT_ACTION.ERROR,
                event_type: tealiumEventType,
                event_name: languageFile.failureMessage,
                event_version: 'upload document',
            });
        }
    };
    const getDocMeta = (val) => {
        const metaObj = t(categoryList).filter((e) => e.label == val);
        return metaObj[0] || t(docMeta.s1.noCategory)[0];
    };
    const getFormData = (file) => {
        const formData = new FormData();
        formData.append('file', file, file.name);
        formData.append('interviewType', productName.toUpperCase());
        //Small over-ride because Experiece is set to Public for LCI for an unknown reason
        //TODO: update LCI component to make sure experience is properly set
        if (productName === 'LIFE') {
            formData.append('experience', 'Employer');
        }
        else {
            formData.append('experience', experience);
        }
        return formData;
    };
    const renderColumns = () => {
        return (<>
        <Col lg={6} md={6} sm={6} xs={6}>
          {' '}
          {documents.map((document, index) => {
                if (index <= 4)
                    return getSelectedDocuments(document);
            })}{' '}
        </Col>

        <Col lg={6} md={6} sm={6} xs={6}>
          {' '}
          {documents.map((document, index) => {
                if (index > 4)
                    return getSelectedDocuments(document);
            })}{' '}
        </Col>
      </>);
    };
    const renderCategoryColumns = () => {
        return (<>
        {documents?.map((documentDetail, index) => {
                return (<>
              <CategoryCol md={5}>
                <StyledSerial>{index + 1}</StyledSerial>
                <SelectInput key={documentDetail.docId} field={'doc-category'} options={t(categoryList)} data-testid={'doc-category'} initialValue={documentDetail?.label} onChange={(e) => updateCategory(documentDetail, e.target.value)}/>
              </CategoryCol>

              <Col md={7}> {getSelectedDocuments(documentDetail)}</Col>
            </>);
            })}
      </>);
    };
    const updateCategory = (docDetails, val) => {
        let updatedCategory = documents.map(e => {
            let metaDoc = getDocMeta(val);
            if (e.docId == docDetails.docId) {
                return { ...e, label: metaDoc.label, documentTypeTitle: metaDoc.title, documentType: metaDoc.type };
            }
            return e;
        });
        setDocuments(updatedCategory);
    };
    const getSelectedDocuments = (document) => {
        const handleDelete = () => {
            trackEvent({
                event_action: TEALIUM_EVENT.EVENT_ACTION.BUTTON,
                event_type: TEALIUM_EVENT.EVENT_TYPE.DOCUMENT_UPLOAD,
                event_name: 'Delete Uploaded document',
            });
            setDocuments(documents.filter(file => file.docId !== document.docId));
        };
        const colProps = (t(categoryList)).length > 1 ? { md: 8 } : { xs: 12, md: 6, lg: 6 };
        return (<>
        {' '}
        <Row key={document.docId}>
          <StyledCol {...colProps}>
            <Row data-testid={'submitted-doc'} aria-labelledby={document.documentName}>
              <Icon style={{ margin: '2px .5rem 0 1rem' }} name='documents'/> {document.documentName}
            </Row>
          </StyledCol>
          <StyledCol md={3}>
            <StyledLink data-testid={'delete-doc'} aria-label={languageFile.delete} onClick={handleDelete}>
              <Icon color='secondary' name='trash'/> {languageFile.delete}
            </StyledLink>
          </StyledCol>
        </Row>
      </>);
    };
    const documentUploadLabel = productName === 'LIFE'
        ? languageFile.informationalMessage1Life
        : CustomEmployeeText(experience === Experience.EMPLOYEE
            ? `${languageFile.informationalMessage1} ${languageFile.yourAbsenceEE}`
            : `${languageFile.informationalMessage1} ${languageFile.yourAbsenceER}`);
    const showCategory = (documents?.length < 10 && productName !== 'LIFE');
    return (<div data-testid='inline-document-upload'>
      <Loader id='document-upload-spinner' isShown={uploading}>
        <Container>
          {errorMessage !== '' && (<Alert type={'error'} data-testid={'error-alert'}>
              <div>{errorMessage}</div>
            </Alert>)}
          {apiFailureMessage && (<Alert data-testid={'api-error-alert'} type={'error'} aria-labelledby='api-error-alert'>
              {languageFile.apiFailureMessage}
              <a href='/customer/home/contacts' target='_blank'>{languageFile.contactUs}</a>
            </Alert>)}
          <Row data-testid={'informationText'} style={{ marginTop: '1rem' }}>
            {documentUploadLabel}
          </Row>
          <Row data-testid={'acceptableDocumentsText'}>
            {languageFile.informationalMessage2}
          </Row>
          {showCategory ? (<>
                <Row>
                  <p style={{ fontWeight: 'bold', fontSize: 'larger' }}>{languageFile.uploadCategory}</p>
                </Row>
                <Row style={{ marginBottom: '0.5rem' }}>{renderCategoryColumns()}</Row>
                <Row data-testid={'Category'} style={{ marginTop: '1rem' }}>
                  <SelectCategory onUpload={handleFileUpload} eventNum={''} uploadedFiles={documents} documentCategory={documentCategory} uploading={uploading} categoryList={categoryList} setDocumentCategory={setDocumentCategory}/>
                </Row>
              </>) : (<>
                <Row style={{ marginTop: '2rem' }}>
                  <UploadButton onUpload={handleFileUpload} eventNum={''}/>
                </Row>
                <Row style={{ marginBottom: '0.5rem' }}>{renderColumns()}</Row>
              </>)}
        </Container>
      </Loader>
    </div>);
};
export default InlineDocumentUpload;
