import { SelectInput } from '@digitools/honest-react';
import React from 'react';
import { CategoryCol, StyledSerial } from './CategorizationDocumentUpload';
import UploadButton from './UploadButton';
import useTranslate from 'src/hooks/useTranslate';
const SelectCategory = ({ categoryList, uploading, documentCategory, uploadedFiles, eventNum, onUpload, setDocumentCategory, }) => {
    const { t } = useTranslate();
    let showCategory = uploadedFiles?.length < 10 && !uploading && (t(categoryList) && t(categoryList).length !== 1);
    return (<>
      {showCategory && (<CategoryCol md={5} xs={10}>
          <StyledSerial>{uploadedFiles.length + 1}</StyledSerial>
          <SelectInput field={'documentCategory'} options={t(categoryList)} data-testid={'documentCategory'} validateOnBlur={true} onChange={(e) => setDocumentCategory(e.target.value)} placeholderText={t({
                english: 'Select document type',
                spanish: 'Seleccione un tipo de documento',
            })} initialValue={''}/>
        </CategoryCol>)}
      {(documentCategory || (categoryList && t(categoryList).length == 1)) && !uploading && (<UploadButton onUpload={onUpload} eventNum={eventNum}/>)}
    </>);
};
export default SelectCategory;
