import { Alert, Col, Row, TabContainer, TabSection } from '@digitools/honest-react';
import DeductibleAndCoinsurance, { DeductibleAndCoinsuranceInfo, } from 'src/packages/dental/pages/DeductibleAndCoinsurance';
import CoveredServices, { CoveredServicesInfo } from 'src/packages/dental/pages/CoveredServices';
import Claims, { ClaimsInfo } from 'src/packages/dental/pages/Claims';
import useMediaQuery from 'src/hooks/useMediaQuery';
import useDentalDataContext from 'src/packages/dental/hooks/useDentalContext';
import React, { useEffect } from 'react';
import DentalText from 'src/packages/dental/constants/DentalText';
import useTranslate from 'src/hooks/useTranslate';
import { CLAIMS, COVERED_SERVICES, DEDUCTIBLES_AND_COINSURANCE, getMemberEvent, tealiumFormat, } from 'src/packages/dental/providers/DentalDataProvider';
import DentalButton from 'src/packages/dental/components/DentalButton';
import { TEALIUM_EVENT } from 'src/constants/tealiumConstants';
import useTealium from 'src/hooks/useTealium';
import { GetBenefitsAndFrequencies, GetClaims, GetDeductibles, GetWaitingPeriods, HIPAA_VIOLATION, } from 'src/packages/dental/api/DentalApiCalls';
import useAuthentication from 'src/hooks/useAuthentication';
import useExperience from 'src/hooks/useExperience';
const TabContent = () => {
    const isMobile = useMediaQuery('(max-width: 768px)');
    const isTitleView = useMediaQuery('(max-width: 738px)');
    const { activeTab, setActiveTab, subscriber, currentMember, setCoveredServicesLoading, setCurrentMember, setDeductiblesLoading, setClaimsLoading } = useDentalDataContext();
    const { trackEvent } = useTealium();
    const { t } = useTranslate();
    const { authHeader } = useAuthentication();
    const { language } = useExperience();
    const hasWaitingPeriodData = currentMember?.waitingPeriods || currentMember?.waitingPeriodsError;
    const hasBenefitsData = currentMember?.benefitsError || currentMember?.benefitsAndFrequencies;
    const needsDeductibleData = currentMember && !currentMember.deductiblesError && !currentMember.deductiblesAndCoinsurance;
    const needsClaimData = currentMember && !currentMember?.claims && !currentMember?.claimSummaryError && !currentMember?.hipaaViolation;
    useEffect(() => {
        if (!isTitleView) {
            trackEvent({
                event_action: TEALIUM_EVENT.EVENT_ACTION.TAB,
                event_name: tealiumFormat(activeTab),
                event_type: TEALIUM_EVENT.EVENT_TYPE.DENTAL_INSURANCE,
                event_version: getMemberEvent(currentMember, subscriber),
            });
        }
    }, [activeTab]);
    useEffect(() => {
        if (needsClaimData && currentMember && activeTab == CLAIMS) {
            setClaimsLoading(true);
            GetClaims(authHeader(), language, currentMember.memberType, currentMember.id)
                .then((claimsResponse) => {
                currentMember.claims = claimsResponse?.claims;
                if (claimsResponse.graphQlError === HIPAA_VIOLATION) {
                    currentMember.hipaaViolation = true;
                }
                else {
                    currentMember.claimSummaryError = claimsResponse?.graphQlError;
                }
                setCurrentMember(currentMember);
            })
                .finally(() => {
                setClaimsLoading(false);
            });
        }
        if (needsDeductibleData && activeTab == DEDUCTIBLES_AND_COINSURANCE) {
            setDeductiblesLoading(true);
            GetDeductibles(authHeader(), language, currentMember.memberType, currentMember.id)
                .then((deductiblesResponse) => {
                currentMember.deductiblesAndCoinsurance = deductiblesResponse?.deductiblesAndCoinsurance;
                currentMember.deductiblesError = deductiblesResponse.graphQlError;
                setCurrentMember(currentMember);
            })
                .finally(() => {
                setDeductiblesLoading(false);
            });
        }
        if (currentMember && activeTab != CLAIMS && activeTab != DEDUCTIBLES_AND_COINSURANCE) {
            setCoveredServicesLoading(true);
            (async () => {
                if (!hasWaitingPeriodData) {
                    const waitingPeriodsResponse = await GetWaitingPeriods(authHeader(), language, currentMember.memberType, currentMember.id);
                    currentMember.waitingPeriods = waitingPeriodsResponse?.waitingPeriods;
                    currentMember.waitingPeriodsError = waitingPeriodsResponse.graphQlError;
                }
                if (!hasBenefitsData) {
                    const benefitsResponse = await GetBenefitsAndFrequencies(authHeader(), language, currentMember.id);
                    currentMember.benefitsAndFrequencies = benefitsResponse?.benefitsAndFrequencies;
                    currentMember.benefitsError = benefitsResponse.graphQlError;
                }
            })().finally(() => {
                setCurrentMember(currentMember);
                setCoveredServicesLoading(false);
            });
        }
    }, [activeTab, currentMember?.id]);
    return (<>
      {isMobile ? (<>
          {!activeTab && (<>
              <Row className={'mt-3'}>
                <Col>
                  <p>{t(DentalText.disclaimerText)}</p>
                </Col>
              </Row>
              <Row>
                <DentalButton isInline={true} event={tealiumFormat(COVERED_SERVICES)} component={COVERED_SERVICES} 
            // TODO: change honest version to 4.4.9
            title={t(DentalText.coveredServicesText)} imageName='covered-services'/>
                <DentalButton isInline={true} event={tealiumFormat(DEDUCTIBLES_AND_COINSURANCE)} component={DEDUCTIBLES_AND_COINSURANCE} title={t(DentalText.deductibleText)} imageName='deductibles-and-coinsurance'/>
                <DentalButton isInline={true} event={tealiumFormat(CLAIMS)} component={CLAIMS} title={t(DentalText.claimsText)} imageName='claims'/>
              </Row>
            </>)}
          {activeTab === COVERED_SERVICES && <CoveredServices />}
          {activeTab === DEDUCTIBLES_AND_COINSURANCE && <DeductibleAndCoinsurance />}
          {activeTab === CLAIMS && <Claims />}
        </>) : (<div style={{
                paddingTop: '27px',
                textAlign: 'left',
            }} className='mb-4'>
          <p>{t(DentalText.disclaimerText)}</p>
          <TabContainer initialActiveTab={(activeTab || COVERED_SERVICES) + '_tab'}>
            <TabSection label={t(DentalText.coveredServicesText)} name={`${COVERED_SERVICES}_tab`} onClick={() => {
                setActiveTab(COVERED_SERVICES);
            }}>
              {subscriber?.planOverview?.inNetworkOnlyClause && (<Alert type={'info'}>{subscriber.planOverview.inNetworkOnlyClause}</Alert>)}
              <CoveredServicesInfo />
            </TabSection>
            <TabSection label={t(DentalText.deductibleText)} name={`${DEDUCTIBLES_AND_COINSURANCE}_tab`} aria-selected={true} onClick={() => {
                setActiveTab(DEDUCTIBLES_AND_COINSURANCE);
            }}>
              {subscriber?.planOverview?.inNetworkOnlyClause && (<Alert type={'info'}>{subscriber.planOverview.inNetworkOnlyClause}</Alert>)}
              <DeductibleAndCoinsuranceInfo />
            </TabSection>
            <TabSection label={t(DentalText.claimsText)} name={`${CLAIMS}_tab`} onClick={() => {
                setActiveTab(CLAIMS);
            }}>
              {subscriber?.planOverview?.inNetworkOnlyClause && (<Alert type={'info'}>{subscriber.planOverview.inNetworkOnlyClause}</Alert>)}
              <ClaimsInfo />
            </TabSection>
          </TabContainer>
        </div>)}
    </>);
};
export default TabContent;
