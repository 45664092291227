import React from 'react';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import Dental from 'src/packages/dental/Dental';
import useAuthentication from "src/hooks/useAuthentication";
const DentalRouter = () => {
    const DentalGuard = ({ user }) => {
        return user?.isIdentityVerified ? <Outlet /> : <Navigate to={`/prove`} replace/>;
    };
    const { user } = useAuthentication();
    return (<Routes>
        <Route element={<DentalGuard user={user}/>}>
          <Route path={`*`} element={<Dental />}/>
          <Route path={`openTab=*`} element={<Dental />}/>
        </Route>
    </Routes>);
};
export default DentalRouter;
