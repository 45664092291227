import React, { useEffect } from 'react';
import { Heading, Button, Icon } from '@digitools/honest-react';
import LCIApi from '../../../api/LCIApi';
import useAuthentication from '../../../hooks/useAuthentication';
import { isObjectEmpty } from '../../../utils/validationUtils';
import useBdmLciConfig from '../../../hooks/useBdmLciConfig';
import useExperience from '../../../hooks/useExperience';
import { getLabels } from '../utils/LCIUtils';
import useRouteCheck from '../../../hooks/useRouteCheck';
import useTealium from '../../../hooks/useTealium';
import { TEALIUM_EVENT, TEALIUM_PAGE_VIEW } from '../../../constants/tealiumConstants';
import { Experience } from 'src/types/Experience';
const LCIConfirmation = (props) => {
    const { setLciCompleted } = useRouteCheck();
    const { authHeader } = useAuthentication();
    const { customContents } = useBdmLciConfig();
    const { language } = useExperience();
    const getCustomLabels = getLabels(customContents, language);
    const labelEmployee = getCustomLabels.employee || 'Employee';
    const { trackView, trackEvent } = useTealium();
    const { experience } = useExperience();
    useEffect(() => {
        setLciCompleted(true);
    }, []);
    const handlePdfDownload = () => {
        const claimData = {
            employerInfo: props.sections.employerInfo,
            employeeInfo: props.sections.employeeInfo,
            policyInfo: props.sections.policyInformation,
            dependentInfo: isObjectEmpty(props.sections.dependentInfo) ? undefined : props.sections.dependentInfo,
            callerInfo: isObjectEmpty(props.sections.callerInfo) ? undefined : props.sections.callerInfo,
            additionalInfo: {
                notes: props.sections.additionalInfo.notes || '',
                documents: (props.sections.additionalInfo.documents || []).map((doc) => ({ id: doc.docId, name: doc.documentName }))
            },
            signatureInfo: props.signatureInfo,
            claimFlow: props.sectionTitle,
            dateOfSubmission: props.dateOfSubmission,
            claimId: props.submissionId,
            policyBeneficiaries: props.sections.policyBeneficiaries,
            eventType: props.eventType,
            claimantType: props.claimantType,
            title: labelEmployee
        };
        if (!isObjectEmpty(props.sections.dependentInfo)) {
            claimData.policyInfo.dateOfDeath = props.sections.dependentInfo.dateOfDeath;
        }
        LCIApi().downloadPdf(claimData, authHeader()).then((response) => {
            const blob = new Blob([response.data]);
            // Removed code was IE specific - IM
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${props.submissionId}-claim.pdf`);
            document.body.appendChild(link);
            link.click();
            // @ts-ignore
            link.parentNode.removeChild(link);
        });
        trackEvent({
            event_action: TEALIUM_EVENT.EVENT_ACTION.DOWNLOAD,
            event_name: 'download your claim',
            event_type: TEALIUM_EVENT.EVENT_TYPE.LIFE_CLAIM_INTAKE,
            event_version: 'confirmation',
        });
    };
    useEffect(() => {
        trackView({
            page_l3: TEALIUM_PAGE_VIEW.PAGE_L3.LCI,
            page_l4: experience === Experience.EMPLOYEE ? TEALIUM_PAGE_VIEW.PAGE_L4.HOME_PAGE.HOME_PAGE_EE : TEALIUM_PAGE_VIEW.PAGE_L4.HOME_PAGE.HOME_PAGE_ER,
            page_l5: 'confirmation',
            transaction_id: props.submissionId,
            event_results: 'claim',
        });
    }, []);
    return (<div data-public={true}>
      <Heading className='mt-5' color={'primary'}>
        Confirmation
      </Heading>
      <p>
        <b>Thank you, your claim has been submitted and its being reviewed.</b>
      </p>
      <Heading elemType={'h2'} color={'primary'} className='mt-5'>
        Your Claim Information:
      </Heading>
      <p>
        <b>Claim Number: </b>
        {props.submissionId}
      </p>
      <p>
        <b>Submitted on: </b>
        {props.dateOfSubmission + '.'}
      </p>

      <Button onClick={handlePdfDownload} type={'button'} size={'medium'} className='mt-4 pl-0'>
        <Icon className={'pr-2'} name={'pdf'} color={'primaryDark'} style={{ fontSize: '1.5rem' }}/> Download Your Claim
      </Button>
      <Heading elemType={'h3'} color={'primary'} className='mt-5'>
        Next Steps
      </Heading>
      <ul>
        <li>
          A member of our Life Claims team will contact the claimant or beneficiary to obtain any additionally required
          Information and provide information regarding the claims process.
        </li>
        <li>
          You may also call your Lincoln Financial Group Account Service Manager with
          any questions regarding the claims process or next steps.
        </li>
      </ul>
    </div>);
};
export default LCIConfirmation;
