import React, { useContext, useEffect } from 'react';
import { Box, Heading, Icon, StepperContext } from '@digitools/honest-react';
import { returnLabelByValue } from './../../utils/reasonForTimeOffTypes';
import useGoogleAnalytics from '../../../../../hooks/useGoogleAnalytics';
import useTealium from '../../../../../hooks/useTealium';
import { TEALIUM_PAGE_VIEW } from '../../../../../constants/tealiumConstants';
import { getEnvVariable } from "src/utils/envUtils";
const ConfirmationScreen = ({ employeeName, timeOffValues, transactionNumber, triggerFormReset, resetState }) => {
    const { trackClickWithPage } = useGoogleAnalytics();
    const { trackView } = useTealium();
    useEffect(() => {
        trackView({
            page_l3: TEALIUM_PAGE_VIEW.PAGE_L3.USAA,
            page_l4: TEALIUM_PAGE_VIEW.PAGE_L4.USAA.CONFIRMATION,
        });
    }, []);
    const home = () => {
        trackClickWithPage('Return to Home', '/uaMobile/confirmation');
        triggerFormReset();
        stepper.goToStep(0);
        resetState();
    };
    const stepper = useContext(StepperContext);
    return (<>
    {['SELF', 'FAMILY'].includes(timeOffValues.reasonForTimeOff) && getEnvVariable('enableNewUsaaUaExperience') &&
            <span data-testid={'fmla-warning-header-span'}>
        <Icon style={{ color: 'orange' }} name={'lock'}/>
        <Heading className={'mt-1 mb-2 font-weight-normal'} elemStyle={'h4'}><b>ATTENTION:</b> If your absence is related to an intermittent FMLA claim, it <u><b>must also</b></u> be reported directly to Lincoln Financial at <i><b>800-210-8722, option 1 and then option 9</b></i> to have the absence applied to your intermittent FMLA claim.<b> Failure to notify Lincoln Financial may result in no job protection for this unplanned absence.</b></Heading>
      </span>}
      <span data-testid={'success-header-span'}>
        <Icon style={{ color: 'green' }} name={'lock'}/>
        <Heading className={'mt-1'} elemStyle={'h4'}>Your Unplanned Time Off Request was successfully submitted.</Heading>
      </span>
      <br />
      <br />
      <Box color={'grayLighter'}>
        <p><strong>Transaction Number: </strong>{transactionNumber}</p>
        <p data-testid='timeoff'><strong>Time Off Date: </strong>{timeOffValues.date}</p>
        <p><strong>Reason: </strong>{returnLabelByValue(timeOffValues.reasonForTimeOff)}</p>
      </Box>
      <>
        <p>An email notification was sent to :</p>
        <p><strong data-private={true}>{`${employeeName}'s `}</strong>Manager</p>
        <p>Your time sheet will be updated shortly. Please check your time sheet to validate the information and prevent any pay issues.</p>
      </>
      <br />
      <br />
      <>
        <a onClick={home}>
          <span>
            <Icon name={'home'}/>
          </span> Return to Home
        </a>
      </>
    </>);
};
export default ConfirmationScreen;
