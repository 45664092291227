import useAuthentication from 'src/hooks/useAuthentication';
import React, { createContext, useEffect, useState } from 'react';
import { GetSubscriber } from '../api/DentalApiCalls';
import useExperience from 'src/hooks/useExperience';
import { TEALIUM_EVENT, TEALIUM_PAGE_VIEW } from 'src/constants/tealiumConstants';
import useTealium from 'src/hooks/useTealium';
export var MemberType;
(function (MemberType) {
    MemberType["SUBSCRIBER"] = "SUBSCRIBER";
    MemberType["SPOUSEorPARTNER"] = "SPOUSE or PARTNER";
    MemberType["DEPENDENTCHILD"] = "DEPENDENT CHILD";
})(MemberType || (MemberType = {}));
export var CoverageSubType;
(function (CoverageSubType) {
    CoverageSubType["SELF_FUNDED"] = "SELF_FUNDED";
    CoverageSubType["FULLY_INSURED"] = "FULLY_INSURED";
    CoverageSubType["VOLUNTARY"] = "VOLUNTARY";
})(CoverageSubType || (CoverageSubType = {}));
export var ServiceCategoryEnum;
(function (ServiceCategoryEnum) {
    ServiceCategoryEnum["TYPE_1"] = "TYPE_1";
    ServiceCategoryEnum["TYPE_2"] = "TYPE_2";
    ServiceCategoryEnum["TYPE_3"] = "TYPE_3";
    ServiceCategoryEnum["TYPE_4"] = "TYPE_4";
})(ServiceCategoryEnum || (ServiceCategoryEnum = {}));
export const tealiumFormat = (str) => {
    return str.replaceAll('_', ' ').toLowerCase();
};
export const COVERED_SERVICES = 'covered_services';
export const DEDUCTIBLES_AND_COINSURANCE = 'deductibles_and_coinsurance';
export const CLAIMS = 'claims';
export const getTabs = (deductibleType, currentMember) => {
    const deductiblesAndCoinsurances = currentMember?.deductiblesAndCoinsurance;
    const inNetworkLabel = deductiblesAndCoinsurances?.inNetworkLabel || '';
    const outNetworkLabel = deductiblesAndCoinsurances?.outNetworkLabel || '';
    const data = deductiblesAndCoinsurances && deductiblesAndCoinsurances[deductibleType];
    return [inNetworkLabel, outNetworkLabel, deductiblesAndCoinsurances, data];
};
export const DentalDataContext = createContext({
    subscriber: undefined,
    setSubscriber: () => { },
    currentMember: undefined,
    setCurrentMember: () => { },
    error: undefined,
    setError: () => { },
    loading: true,
    setLoading: () => { },
    claimsLoading: true,
    setClaimsLoading: () => { },
    deductiblesLoading: true,
    setDeductiblesLoading: () => { },
    benefitsPdfLoading: true,
    setBenefitsPdfLoading: () => { },
    coveredServicesLoading: true,
    setCoveredServicesLoading: () => { },
    activeTab: '',
    setActiveTab: () => { },
    tealiumFunction: Function,
});
export const getMemberEvent = (currentMember, subscriber) => {
    if (currentMember && currentMember?.id !== subscriber?.id) {
        return 'member' + subscriber?.dependents?.indexOf(currentMember);
    }
    else {
        return 'subscriber';
    }
};
export const tealiumFunctionBuilder = (config) => {
    return () => (opts) => {
        config.trackEvent({
            event_action: opts?.eventAction || TEALIUM_EVENT.EVENT_ACTION.BUTTON,
            event_name: opts?.eventName || TEALIUM_EVENT.EVENT_NAME.MEMBER,
            event_type: opts?.eventType || TEALIUM_EVENT.EVENT_TYPE.DENTAL_INSURANCE,
            event_version: opts?.memberEvent,
        });
    };
};
export const DentalDataProvider = ({ children, }) => {
    const { trackEvent, trackView } = useTealium();
    const { authHeader } = useAuthentication();
    const [loading, setLoading] = useState(true);
    const [claimsLoading, setClaimsLoading] = useState(false);
    const [deductiblesLoading, setDeductiblesLoading] = useState(false);
    const [coveredServicesLoading, setCoveredServicesLoading] = useState(false);
    const [benefitsPdfLoading, setBenefitsPdfLoading] = useState(false);
    const [subscriber, setSubscriber] = useState();
    const [currentMember, setCurrentMember] = useState();
    const [activeTab, setActiveTab] = useState('');
    const tealiumFunction = (opts) => {
        trackEvent({
            event_action: opts?.eventAction || TEALIUM_EVENT.EVENT_ACTION.BUTTON,
            event_name: opts?.eventName || TEALIUM_EVENT.EVENT_NAME.MEMBER,
            event_type: opts?.eventType || TEALIUM_EVENT.EVENT_TYPE.DENTAL_INSURANCE,
            event_version: opts?.memberEvent,
        });
    };
    const [error, setError] = useState('');
    const { language } = useExperience();
    useEffect(() => {
        trackView({
            page_l3: TEALIUM_PAGE_VIEW.PAGE_L3.DENTAL,
            page_l4: 'employee view',
        });
    }, []);
    useEffect(() => {
        setLoading(true);
        GetSubscriber(authHeader(), language)
            .catch(error => {
            error.subscriber = error.message;
            setError(error);
        })
            .then((subscriberResponse) => {
            const subscriber = subscriberResponse?.subscriber;
            setSubscriber(subscriber);
            if (typeof currentMember === 'undefined') {
                setCurrentMember(subscriber);
            }
            if (subscriberResponse?.graphQlError) {
                setError(subscriberResponse?.graphQlError);
            }
        })
            .finally(() => {
            setLoading(false);
        });
    }, [language]);
    const dentalDataContext = {
        subscriber,
        setSubscriber,
        currentMember,
        setCurrentMember,
        error,
        setError,
        loading,
        setLoading,
        deductiblesLoading,
        setDeductiblesLoading,
        claimsLoading,
        setClaimsLoading,
        coveredServicesLoading,
        setCoveredServicesLoading,
        benefitsPdfLoading,
        setBenefitsPdfLoading,
        activeTab,
        setActiveTab,
        tealiumFunction,
    };
    return <DentalDataContext.Provider value={dentalDataContext}>{children}</DentalDataContext.Provider>;
};
