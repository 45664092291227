import { Box, Heading, SelectInput } from '@digitools/honest-react';
import DentalText from 'src/packages/dental/constants/DentalText';
import React from 'react';
import useDentalDataContext from 'src/packages/dental/hooks/useDentalContext';
import useTranslate from 'src/hooks/useTranslate';
import { TEALIUM_EVENT } from 'src/constants/tealiumConstants';
import { getMemberEvent } from 'src/packages/dental/providers/DentalDataProvider';
const MemberDropdown = () => {
    const { currentMember, subscriber, benefitsPdfLoading, tealiumFunction, setCurrentMember, deductiblesLoading, coveredServicesLoading, claimsLoading } = useDentalDataContext();
    const { t } = useTranslate();
    const dependents = subscriber?.dependents || [];
    const dropdownOptions = [subscriber, ...dependents]
        .filter(member => member?.id != currentMember?.id);
    dropdownOptions.splice(0, 0, currentMember);
    return (<>
      {!!currentMember && !!subscriber && (<Box style={{ paddingBottom: 0.2 }} color={'primary'} className={'pb-0'} data-testid='dental-dropdown-box' type={'header'}>
          <Heading color={'white'} elemType={'h4'} elemStyle={'h2'} style={{ paddingBottom: '10px', fontSize: '21px' }}>
            {t(DentalText.dropdownText)}
          </Heading>
          <SelectInput hidePlaceholder={true} aria-label={'Select a Member'} ariaLabelText={'Member'} aria-haspopup={'listbox'} aria-expanded={false} data-testid='member-dropdown' field={'member'} title={'member-dropdown'} className={'font-weight-normal pt-0 pb-0'} style={{ lineHeight: '2rem' }} disabled={benefitsPdfLoading || deductiblesLoading || coveredServicesLoading || claimsLoading} initialValue={`${currentMember.firstName} ${currentMember.lastName}`} options={[
                ...(dropdownOptions.map(member => ({
                    label: `${member?.firstName} ${member?.lastName}`,
                    value: member?.id,
                })) || []),
            ]} onChange={(event) => {
                const member = dropdownOptions.find(member => member?.id && member.id === event.target.value);
                if (member) {
                    setCurrentMember(member);
                    tealiumFunction({ memberEvent: getMemberEvent(member, subscriber), eventAction: TEALIUM_EVENT.EVENT_ACTION.TAB });
                }
            }} required={true}/>
        </Box>)}
    </>);
};
export default MemberDropdown;
