import { Button, Col, Heading, Row, StepperContext, TextInput, } from '@digitools/honest-react';
import { Form } from 'informed';
import React, { useContext, useEffect, useState } from 'react';
import useAuthentication from '../../../../hooks/useAuthentication';
import { UnexpectedErrorContext } from '../../../../providers/UnexpectedErrorProvider';
import { validateNotes } from '../../../../utils/validationUtils';
import CancelLci from '../cancelLCI/CancelLci';
import useTealium from '../../../../hooks/useTealium';
import { TEALIUM_EVENT } from '../../../../constants/tealiumConstants';
import { BorderLessGrayBox, StyledDiv } from 'src/components/Formatting/StyledComponents';
import InlineDocumentUpload from 'src/components/documentUpload/InlineDocumentUpload';
const DOCS_INITIAL_STATE = [];
const AdditionalInfo = ({ onComplete, claimant, event }) => {
    const stepper = useContext(StepperContext);
    const [docs, setDocs] = useState(DOCS_INITIAL_STATE);
    const { trackEvent } = useTealium();
    const { setIsMessagingDisabled } = useContext(UnexpectedErrorContext);
    useEffect(() => setIsMessagingDisabled(false), []);
    const { authHeader } = useAuthentication();
    const eventStatus = (claimant, event) => {
        if (event === 'death') {
            return 'step 7';
        }
        else if (((event === 'accelerated death' || event === `dismemberment / loss of sight`) && claimant !== 'dependent') ||
            event === 'total disability') {
            return 'step 5';
        }
        else {
            return 'step 6';
        }
    };
    function handleContinue(values) {
        trackEvent({
            event_action: TEALIUM_EVENT.EVENT_ACTION.SELF_SERVICE,
            event_name: 'continue',
            event_type: TEALIUM_EVENT.EVENT_TYPE.LIFE_CLAIM_INTAKE,
            event_version: 'additional info',
            event_status: `${eventStatus(claimant, event)}`,
        });
        const documents = docs;
        const additionalInfo = documents.length > 0 || (values && values.notes) ? { ...values, documents } : {};
        onComplete({ ...additionalInfo });
        stepper.next();
    }
    const handleBack = () => {
        stepper.back();
    };
    return (<div data-public={true}>
      <Form onSubmit={handleContinue}>
        <Heading elemType='h2' color='primary'>
          Additional Info (Optional)
        </Heading>
        <Row>
          <Col>
            <BorderLessGrayBox>
              <Heading elemType='h3' elemStyle='h4'>
                Upload Documents
              </Heading>
            </BorderLessGrayBox>
          </Col>
        </Row>

        <Row>
          <Col>
            <InlineDocumentUpload productName={'LIFE'} documents={docs} setDocuments={setDocs}/>
            <BorderLessGrayBox>
              <Heading elemType='h3' elemStyle='h4'>
                Notes
              </Heading>
            </BorderLessGrayBox>
          </Col>
        </Row>
        <Row>
          <Col sm={12} md={12}>
            <TextInput label='Enter any other relevant information to your claim.' field='notes' textarea={true} validateOnChange={true} validate={validateNotes}/>
          </Col>
        </Row>
        <StyledDiv>
          <Button aria-label={'Back'} buttonType={'secondary'} role='button' id='back-additional-info' type='button' onClick={handleBack}>
            Back
          </Button>

          <CancelLci step={eventStatus(claimant, event)} pageTitle={'additional info'} sectionInfo={'Additional info'}/>
          <Button aria-label={'Continue'} buttonType='primary' role='button' id='continue-additional-info'>
            Continue
          </Button>
        </StyledDiv>
      </Form>
    </div>);
};
export default AdditionalInfo;
