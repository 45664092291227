export const docMeta = {
    s1: {
        claimsEE: {
            english: [
                {
                    label: 'Authorization forms',
                    type: 'EMP',
                    title: 'AUTH',
                },
                {
                    label: 'Employee signed forms',
                    type: 'EMP',
                    title: 'FORM',
                    isRtwEligible: true,
                },
                {
                    label: 'Doctor signed forms',
                    type: 'MED',
                    title: 'FRMTRTNGPVDR',
                    isRtwEligible: true,
                },
                {
                    label: 'Medical records',
                    type: 'MED',
                    title: 'MEDREC',
                    isRtwEligible: true,
                },
                {
                    label: 'Appeal letter',
                    type: 'APPL',
                    title: 'NTCEAPPL',
                },
                {
                    label: 'Other non-medical documentation',
                    type: 'EMP',
                    title: 'CORR',
                    isRtwEligible: true,
                },
            ],
            spanish: [
                {
                    label: 'Formularios de autorización',
                    type: 'EMP',
                    title: 'AUTH',
                },
                {
                    label: 'Formularios firmados por los empleados',
                    type: 'EMP',
                    title: 'FORM',
                    isRtwEligible: true,
                },
                {
                    label: 'Formularios firmados por el médico',
                    type: 'MED',
                    title: 'FRMTRTNGPVDR',
                    isRtwEligible: true,
                },
                {
                    label: 'Registros médicos',
                    type: 'MED',
                    title: 'MEDREC',
                    isRtwEligible: true,
                },
                {
                    label: 'Carta de apelación',
                    type: 'APPL',
                    title: 'NTCEAPPL',
                },
                {
                    label: 'Otra documentación no médica',
                    type: 'EMP',
                    title: 'CORR',
                    isRtwEligible: true,
                },
            ],
        },
        claimsER: {
            english: [
                {
                    label: 'Job description',
                    type: 'EPR',
                    title: 'JOBDES'
                },
                {
                    label: 'Eligibility information',
                    type: 'EPR',
                    title: 'EPRELIG',
                },
                {
                    label: 'Employee signed forms',
                    type: 'EMP',
                    title: 'FORM'
                },
                {
                    label: 'Medical records',
                    type: 'MED',
                    title: 'MEDREC'
                },
                {
                    label: 'Other non-medical documentation',
                    type: 'EPR',
                    title: 'CORR',
                    isRtwEligible: true,
                }
            ],
            spanish: [
                {
                    label: '',
                    type: '',
                    title: ''
                },
            ]
        },
        leaves: {
            english: [
                {
                    label: 'Medical certification form',
                    type: 'LVE',
                    title: 'MEDCERT',
                },
                {
                    label: 'Proof of birth',
                    type: 'LVE',
                    title: 'BRTHCERT',
                },
                {
                    label: 'Adoption/Foster care certification',
                    type: 'LVE',
                    title: 'ADPTCERT',
                },
                {
                    label: 'Other correspondence',
                    type: 'LVE',
                    title: 'MISCCORR',
                    isRtwEligible: true,
                },
            ],
            spanish: [
                {
                    label: 'Formulario de certificación médica',
                    type: 'LVE',
                    title: 'MEDCERT',
                },
                {
                    label: 'Prueba de nacimiento',
                    type: 'LVE',
                    title: 'BRTHCERT',
                },
                {
                    label: 'Certificación de adopción/cuidado de crianza',
                    type: 'LVE',
                    title: 'ADPTCERT',
                },
                {
                    label: 'Otra correspondencia',
                    type: 'LVE',
                    title: 'MISCCORR',
                    isRtwEligible: true,
                },
            ],
        },
        coordinatedEE: {
            english: [
                {
                    label: 'Authorization forms',
                    type: 'EMP',
                    title: 'AUTH',
                },
                {
                    label: 'Employee signed forms',
                    type: 'EMP',
                    title: 'FORM',
                },
                {
                    label: 'Doctor signed forms',
                    type: 'MED',
                    title: 'FRMTRTNGPVDR',
                },
                {
                    label: 'Medical records',
                    type: 'MED',
                    title: 'MEDREC',
                    isRtwEligible: true,
                },
                {
                    label: 'Other non-medical documentation',
                    type: 'EMP',
                    title: 'CORR',
                    isRtwEligible: true,
                },
                {
                    label: 'Medical certification form',
                    type: 'LVE',
                    title: 'MEDCERT',
                },
                {
                    label: 'Proof of birth',
                    type: 'LVE',
                    title: 'BRTHCERT',
                },
                {
                    label: 'Adoption/Foster care certification',
                    type: 'LVE',
                    title: 'ADPTCERT',
                },
            ],
            spanish: [
                {
                    label: 'Formularios de autorización',
                    type: 'EMP',
                    title: 'AUTH',
                },
                {
                    label: 'Formularios firmados por los empleados',
                    type: 'EMP',
                    title: 'FORM',
                },
                {
                    label: 'Formularios firmados por el médico',
                    type: 'MED',
                    title: 'FRMTRTNGPVDR',
                },
                {
                    label: 'Registros médicos',
                    type: 'MED',
                    title: 'MEDREC',
                },
                {
                    label: 'Otra documentación no médica',
                    type: 'EMP',
                    title: 'CORR',
                },
                {
                    label: 'Formulario de certificación médica',
                    type: 'LVE',
                    title: 'MEDCERT',
                },
                {
                    label: 'Prueba de nacimiento',
                    type: 'LVE',
                    title: 'BRTHCERT',
                },
                {
                    label: 'Certificación de adopción/cuidado de crianza',
                    type: 'LVE',
                    title: 'ADPTCERT',
                },
            ],
        },
        coordinatedER: {
            english: [
                {
                    label: 'Job description',
                    type: 'EPR',
                    title: 'JOBDES'
                },
                {
                    label: 'Eligibility information',
                    type: 'EPR',
                    title: 'EPRELIG',
                },
                {
                    label: 'Employee signed forms',
                    type: 'EMP',
                    title: 'FORM'
                },
                {
                    label: 'Medical records',
                    type: 'MED',
                    title: 'MEDREC'
                },
                {
                    label: 'Other non-medical documentation',
                    type: 'EPR',
                    title: 'CORR',
                    isRtwEligible: true,
                },
                {
                    label: 'Medical certification form',
                    type: 'LVE',
                    title: 'MEDCERT'
                }
            ],
            spanish: [
                {
                    label: '',
                    type: '',
                    title: ''
                },
            ]
        },
        noCategory: {
            english: [
                {
                    label: 'No Categories',
                    type: '',
                    title: ''
                }
            ], spanish: [
                {
                    label: 'Sin categorías',
                    type: '',
                    title: ''
                }
            ],
        }
    },
    docsNet: {
        claimsEE: {
            english: [
                {
                    label: 'Authorization forms',
                    type: 'EMP',
                    title: 'AUTH',
                },
                {
                    label: 'Employee signed forms',
                    type: 'EMP',
                    title: 'FORM',
                },
                {
                    label: 'Doctor signed forms',
                    type: 'MED',
                    title: 'FRMTRTNGPVDR',
                },
                {
                    label: 'Medical records',
                    type: 'MED',
                    title: 'MEDREC',
                    isRtwEligible: true,
                },
                {
                    label: 'Social Security documents',
                    type: 'SOC',
                    title: 'CORR',
                },
                {
                    label: 'Other non-medical documentation',
                    type: 'EMP',
                    title: 'CORR',
                    isRtwEligible: true,
                },
            ],
            spanish: [
                {
                    label: 'Formularios de autorización',
                    type: 'EMP',
                    title: 'AUTH',
                },
                {
                    label: 'Formularios firmados por los empleados',
                    type: 'EMP',
                    title: 'FORM',
                },
                {
                    label: 'Formularios firmados por el médico',
                    type: 'MED',
                    title: 'FRMTRTNGPVDR',
                },
                {
                    label: 'Registros médicos',
                    type: 'MED',
                    title: 'MEDREC',
                    isRtwEligible: true,
                },
                {
                    label: 'Documentos de seguridad social',
                    type: 'SOC',
                    title: 'CORR',
                },
                {
                    label: 'Otra documentación no médica',
                    type: 'EMP',
                    title: 'CORR',
                    isRtwEligible: true,
                },
            ],
        }
    },
};
Object.assign(docMeta.s1, { intregatedLeaves: { ...docMeta.s1.claimsEE, ...docMeta.s1.leaves } });
