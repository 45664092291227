import React, { useContext, useEffect, useState } from 'react';
import { StepperContext, Row, Button, Box, SelectInput, Form, TextInput, Col, Heading, NewDateInput, themes } from '@digitools/honest-react';
import { Radio, RadioGroup } from 'informed';
import { reasonForTimeOff } from './../../utils/reasonForTimeOffTypes';
import EmployeeNameLogout from './../employeeNameLogout/EmployeeNameLogout';
import { validateSelectGeneric, timeOffFormValidate, clearAndRelyOnFormForComposite, } from './../../utils/Validator';
import useGoogleAnalytics from '../../../../../hooks/useGoogleAnalytics';
import useTealium from '../../../../../hooks/useTealium';
import { TEALIUM_PAGE_VIEW } from '../../../../../constants/tealiumConstants';
import { getEnvVariable } from "src/utils/envUtils";
import styled from "styled-components";
const TimeOff = ({ employeeName, resetStatus, updateFormState, triggerFormReset }) => {
    const [formApi, setFormApi] = useState();
    const resetFormState = () => {
        if (formApi) {
            formApi.reset();
        }
    };
    const stepper = useContext(StepperContext);
    const { trackClickWithPage } = useGoogleAnalytics();
    const { trackView } = useTealium();
    useEffect(() => {
        trackView({
            page_l3: TEALIUM_PAGE_VIEW.PAGE_L3.USAA,
            page_l4: TEALIUM_PAGE_VIEW.PAGE_L4.USAA.TIME_OFF,
        });
    }, []);
    /* trigger form state reset */
    if (resetStatus === true) {
        stepper.goToStep(0);
        resetFormState();
    }
    const handleContinue = async (values) => {
        trackClickWithPage('Continue', '/uaMobile/timeOff');
        const timeOffValues = {
            timeOffType: values.timeOffType,
            durationHours: values.durationHours,
            durationMinutes: values.durationMinutes,
            reasonForTimeOff: values.reasonForTimeOff,
            estimatedReturn: values.estimatedReturnHours + ':' + values.estimatedReturnMinutes,
            existingLeave: values.existingLeave,
            date: values.date,
            startTime: values.startTimeHours + ':' + values.startTimeMinutes,
            endTime: values.endTimeHours + ':' + values.endTimeMinutes,
            spansMidnight: values.spansMidnight,
            estimatedArrival: values.estimatedArrivalHours + ':' + values.estimatedArrivalMinutes,
            normalStartTime: values.normalStartTimeHours + ':' + values.normalStartTimeMinutes,
            departureTime: values.departureTimeHours + ':' + values.departureTimeMinutes,
            regularEndTime: values.regularEndTimeHours + ':' + values.regularEndTimeMinutes,
            immediateFamily: values.immediateFamily,
            leaveId: values.leaveId,
        };
        await updateFormState(timeOffValues);
        stepper.next();
    };
    const timeOffTypes = [
        { label: 'Absent for full day ', value: 'OUT' },
        { label: 'Arriving to work late', value: 'LATE' },
        { label: 'Leave w/ No Return', value: 'EARLYNORTN' },
        { label: 'Leave w/ Return', value: 'EARLYRTN' },
    ];
    const hoursTypes = [
        { label: '12 AM', value: '00' },
        { label: '1 AM', value: '01' },
        { label: '2 AM', value: '02' },
        { label: '3 AM', value: '03' },
        { label: '4 AM', value: '04' },
        { label: '5 AM', value: '05' },
        { label: '6 AM', value: '06' },
        { label: '7 AM', value: '07' },
        { label: '8 AM', value: '08' },
        { label: '9 AM', value: '09' },
        { label: '10 AM', value: '10' },
        { label: '11 AM', value: '11' },
        { label: '12 PM', value: '12' },
        { label: '1 PM', value: '13' },
        { label: '2 PM', value: '14' },
        { label: '3 PM', value: '15' },
        { label: '4 PM', value: '16' },
        { label: '5 PM', value: '17' },
        { label: '6 PM', value: '18' },
        { label: '7 PM', value: '19' },
        { label: '8 PM', value: '20' },
        { label: '9 PM', value: '21' },
        { label: '10 PM', value: '22' },
        { label: '11 PM', value: '23' },
    ];
    const renderDateSelect = () => {
        return (<>
        <NewDateInput data-testid='date' required={true} validate={clearAndRelyOnFormForComposite} initialValue={new Date().toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' })} field='date' label='Date' minDate={(new Date(new Date().setDate(new Date().getDate() - 5)).toLocaleDateString(), 'P', new Date())} maxDate={(new Date(new Date().setDate(new Date().getDate() + 5)).toLocaleDateString(), 'P', new Date())}/>
        <br />
      </>);
    };
    const renderDuration = () => {
        return (<>
        <Row>
          <Col>
            <SelectInput data-testid={'durationHours'} field={'durationHours'} label={'Duration Hours'} placeholderText={'HH'} required={true} options={createArrayOfDaysSelect(12)}/>
          </Col>
          <Col>
            <SelectInput data-testid={'durationMinutes'} field={'durationMinutes'} label={'Duration Minutes'} placeholderText={'MM'} required={true} options={createArrayOfDaysSelect(59)}/>
          </Col>
        </Row>
        <br />
      </>);
    };
    const createArrayOfDaysSelect = (input) => {
        const returnArray = Array(input)
            .fill(null)
            .map((value, i) => {
            if (i < 9) {
                return { label: `0${i + 1}`, value: `0${i + 1}` };
            }
            else {
                return { label: `${i + 1}`, value: `${i + 1}` };
            }
        });
        returnArray.unshift({ label: '00', value: '00' });
        return returnArray;
    };
    const renderStartAndEndTime = () => {
        return (<>
        <Row>
          <Col>
            <SelectInput data-testid={'startTimeHours'} field={'startTimeHours'} label={'Start Time Hours'} placeholderText={'HH'} required={true} options={hoursTypes}/>
          </Col>
          <Col>
            <SelectInput field={'startTimeMinutes'} label={'Minutes'} placeholderText={'MM'} required={true} options={createArrayOfDaysSelect(59)}/>
          </Col>
        </Row>
        <Row>
          <Col>
            <SelectInput field={'endTimeHours'} label={'End Time Hours'} placeholderText={'HH'} required={true} options={hoursTypes}/>
          </Col>
          <Col>
            <SelectInput field={'endTimeMinutes'} label={'Minutes'} placeholderText={'MM'} required={true} options={createArrayOfDaysSelect(59)}/>
          </Col>
        </Row>
        <br />
      </>);
    };
    const renderNormalStartTimeAndEstimatedArrival = () => {
        return (<>
        <Row>
          <Col>
            <SelectInput field={'normalStartTimeHours'} label={'Normal Start'} placeholderText={'HH'} required={true} options={hoursTypes}/>
          </Col>
          <Col>
            <SelectInput field={'normalStartTimeMinutes'} label={'Minutes'} placeholderText={'MM'} required={true} options={createArrayOfDaysSelect(59)}/>
          </Col>
        </Row>
        <Row>
          <Col>
            <SelectInput field={'estimatedArrivalHours'} label={'Est Arrival'} placeholderText={'HH'} required={true} options={hoursTypes}/>
          </Col>
          <Col>
            <SelectInput field={'estimatedArrivalMinutes'} label={'Minutes'} placeholderText={'MM'} required={true} options={createArrayOfDaysSelect(59)}/>
          </Col>
        </Row>
        <br />
      </>);
    };
    const renderDepartureTime = () => {
        return (<>
        <Row>
          <Col>
            <SelectInput field={'departureTimeHours'} label={'Depart Time'} placeholderText={'HH'} required={true} options={hoursTypes}/>
          </Col>
          <Col>
            <SelectInput field={'departureTimeMinutes'} label={'Minutes'} placeholderText={'MM'} required={true} options={createArrayOfDaysSelect(59)}/>
          </Col>
        </Row>
        <br />
      </>);
    };
    const renderRegularEndTime = () => {
        return (<>
        <Row>
          <Col>
            <SelectInput field={'regularEndTimeHours'} label={'Regular End'} placeholderText={'HH'} required={true} options={hoursTypes}/>
          </Col>
          <Col>
            <SelectInput field={'regularEndTimeMinutes'} label={'Minutes'} placeholderText={'MM'} required={true} options={createArrayOfDaysSelect(59)}/>
          </Col>
        </Row>
        <br />
      </>);
    };
    const renderEstimatedReturn = () => {
        return (<>
        <Row>
          <Col>
            <SelectInput field={'estimatedReturnHours'} label={'Est Return'} placeholderText={'HH'} required={true} options={hoursTypes}/>
          </Col>
          <Col>
            <SelectInput field={'estimatedReturnMinutes'} label={'Minutes'} placeholderText={'MM'} required={true} options={createArrayOfDaysSelect(59)}/>
          </Col>
        </Row>
        <br />
      </>);
    };
    const validateLeaveNumberTextInput = (value) => {
        // Queue has leavenumber as an int
        const leaveNumberRegex = /^[1-9]\d*$/;
        if (value && !leaveNumberRegex.test(value) || Number.parseInt(value) > 2147483647) {
            return 'Please enter a valid leave number.';
        }
        return undefined;
    };
    const Disclaimer = styled.div `
    color: ${themes.lincoln.palette.gray};
    font-style: italic;
  `;
    return (<>
      <Form getApi={setFormApi} onSubmit={handleContinue} validateFields={timeOffFormValidate}>
        {({ formState }) => (<Box color={'grayLighter'}>
            <EmployeeNameLogout triggerFormReset={triggerFormReset} employeeName={employeeName} page='timeOff'/>
            <label style={{ alignContent: 'center' }}>
              <Heading elemStyle={'h4'}>Does your work schedule span midnight?</Heading>
            </label>
            <br />
            <Row style={{ marginLeft: '0px' }}>
              <RadioGroup data-testid='spansMidnight' field='spansMidnight' initialValue={false}>
                <label>
                  Yes <Radio value={true} data-testid='spansMidnightYes' id='spansMidnightYes' style={{ margin: '0 10px 0 10px' }}/>
                </label>
                <label>
                  No <Radio value={false} data-testid='spansMidnightNo' id='spansMidnightNo' style={{ margin: '0 10px 0 10px' }}/>
                </label>
              </RadioGroup>
            </Row>
            <br />
            <SelectInput data-testid={'timeOffType'} field={'timeOffType'} label={'Type of Time Off'} placeholderText={'Select a type'} validateOnBlur={true} 
        /* Seems like validate callback isn't being passed
      and a generic validate method is called
      Look into this. */
        validate={validateSelectGeneric} validateOnChange={true} options={timeOffTypes}/>
            <br />
            {formState.values.timeOffType !== '' ? renderDateSelect() : <></>}
            {formState.values.timeOffType === 'OUT' && !formState.values.spansMidnight ? renderDuration() : <></>}
            {formState.values.timeOffType === 'OUT' && formState.values.spansMidnight ? renderStartAndEndTime() : <></>}
            {formState.values.timeOffType === 'LATE' ? renderNormalStartTimeAndEstimatedArrival() : <></>}
            {formState.values.timeOffType === 'EARLYNORTN' || formState.values.timeOffType === 'EARLYRTN' ? (renderDepartureTime()) : (<></>)}
            {formState.values.timeOffType === 'EARLYNORTN' ? renderRegularEndTime() : <></>}
            {formState.values.timeOffType === 'EARLYRTN' ? renderEstimatedReturn() : <></>}
            <SelectInput data-testid={'reasonForTimeOff'} field={'reasonForTimeOff'} label={'Reason For Time Off'} placeholderText={'Select Reason...'} validate={validateSelectGeneric} validateOnBlur={true} validateOnChange={true} options={reasonForTimeOff}/>
            <br />
            {(formState.values.reasonForTimeOff === 'SELF' || formState.values.reasonForTimeOff === 'FAMILY')
                && !getEnvVariable('enableNewUsaaUaExperience') ? (<>
                <label>
                  <strong>Existing Leave?</strong>
                </label>
                <br />
                <Row style={{ marginLeft: '0px' }}>
                  <RadioGroup data-testid='existingLeave' field='existingLeave' initialValue={false}>
                    <label>
                      Yes <Radio value={true} id='existingLeaveYes' style={{ margin: '0 10px 0 10px' }}/>
                    </label>
                    <label>
                      No <Radio value={false} id='existingLeaveNo' style={{ margin: '0 10px 0 10px' }}/>
                    </label>
                  </RadioGroup>
                </Row>
              </>) : (<></>)}
            {formState.values.reasonForTimeOff === 'BEREAV' ? (<>
                <label>
                  <strong>Immediate Family?</strong>
                </label>
                <br />
                <Row style={{ marginLeft: '0px' }}>
                  <RadioGroup data-testid='immediateFamily' field='immediateFamily' initialValue={false}>
                    <Disclaimer data-testid='family-members-disclaimer' className='mb-3'>
                      {getEnvVariable('enableNewUsaaUaExperience') &&
                    <>
                            The following family members qualify as immediate family defined by USAA's Bereavement Time
                            Off policy: Mother, father, mother-in-law, father-in-law, stepmother, stepfather, sister,
                            brother, spouse, domestic partner, domestic partner’s parents, child (including in utero),
                            stepchild, grandchild, grandmother, grandfather, and primary caregiver. Review the Bereavement
                            Time Off policy to determine the number of Bereavement days you are provided. USAA reserves
                            the right to request reasonable documentation to substantiate the loss of an "immediate family member".
                          </>}
                    </Disclaimer>
                    <label>
                      Yes <Radio value={true} id='immediateFamilyYes' style={{ margin: '0 10px 0 10px' }}/>
                    </label>
                    <label>
                      No <Radio value={false} id='immediateFamilyNo' style={{ margin: '0 10px 0 10px' }}/>
                    </label>
                  </RadioGroup>
                </Row>
              </>) : (<></>)}
            {formState.values.existingLeave ? (<>
                <br />
                <TextInput field={'leaveId'} required={false} label={'Leave ID (optional)'} validateOnBlur={true} validate={validateLeaveNumberTextInput} maxLength={20} initialValue={''} helperText={'You may enter your Leave ID here. If you do not have an open Leave, call 1-800-989-7842 to request one.'}/>
                <br />
              </>) : (<></>)}
            <Row style={{ display: 'flex', justifyContent: 'center' }}>
              <br />
              <Button data-testid={'timeOffButton'} disabled={formState.invalid} buttonType={'primary'} type={'submit'}>
                Continue
              </Button>
            </Row>
          </Box>)}
      </Form>
    </>);
};
export default TimeOff;
