import axios from 'axios';
const NOT_FOUND = 'NOT_FOUND';
export const INTERNAL_SERVER_ERROR = 'INTERNAL_SERVER_ERROR';
export const HIPAA_VIOLATION = 'HIPAA_VIOLATION';
const mapError = (errors) => {
    if (errors[0].message && (errors[0].message?.search('HIPAA') > -1)) {
        return HIPAA_VIOLATION;
    }
    const notFoundError = errors &&
        errors?.find((error) => error.extensions?.classification === NOT_FOUND || error.errorType === NOT_FOUND);
    return notFoundError ? NOT_FOUND : INTERNAL_SERVER_ERROR;
};
const dentalGraphqlUrl = '/mlp-dental-consumer-bff/graphql';
export const GetPdf = async (language, authHeader, memberType, memberId) => {
    const memberTypeValue = (memberType && memberType.valueOf()) || '';
    return axios.get(`/mlp-dental-consumer-bff/services/v1/pdfs/benefit-details?member-type=${memberTypeValue}&member-id=${memberId}`, {
        headers: {
            'X-Language-Header': language,
            'Content-Type': 'application/json',
            Authorization: authHeader,
        },
        responseType: 'blob',
    });
};
export const GetDetailsPdf = async (language, authHeader, memberId, claimNumber) => {
    return axios.get(`/mlp-dental-consumer-bff/services/v1/pdfs/claim-details?&member-id=${memberId}&claim-id=${claimNumber}`, {
        headers: {
            'X-Language-Header': language,
            'Content-Type': 'application/json',
            Authorization: authHeader,
        },
        responseType: 'blob',
    });
};
export const GetSubscriber = async (authHeader, language) => {
    return axios
        .post(dentalGraphqlUrl, {
        query: `
            query subscriber {
                subscriber {
                    firstName
                    lastName
                    id
                    dateOfBirth
                    effectiveDate
                    terminationDate
                    memberType
                    memberTypeDescription
                    status
                    planOverview {
                        groupName {
                            value
                            label
                        }
                        groupPolicyNum {
                            value
                            label
                        }
                        coverageType {
                            value
                            label
                        }
                        productType
                        terminationDate {
                            value
                            label
                        }
                        benefitPeriod {
                            value
                            label
                        }
                        coverageSubtype
                        dependentAgeLimit {
                            value
                            label
                        }
                        studentAgeLimit {
                            value
                            label
                        }
                        flexibleIndicator
                        stateRegulated
                        planFundingClause
                        inNetworkOnlyClause
                    }
                    dependents {
                        firstName
                        lastName
                        id
                        dateOfBirth
                        effectiveDate
                        terminationDate
                        memberType
                        memberTypeDescription
                        status
                }
            }
        }`,
    }, { headers: { authorization: authHeader, 'X-Language-Header': language } })
        .then(response => {
        const subscriber = response?.data?.data?.subscriber;
        const errors = response?.data?.errors;
        return {
            subscriber: subscriber,
            graphQlError: (errors && mapError(errors)) || (subscriber ? null : INTERNAL_SERVER_ERROR),
        };
    })
        .catch(exception => {
        return { subscriber: undefined, graphQlError: INTERNAL_SERVER_ERROR };
    });
};
export const GetDeductibles = async (authHeader, language, memberType, memberId) => {
    return axios
        .post(dentalGraphqlUrl, {
        query: `
            query deductiblesAndCoinsurance {
                deductiblesAndCoinsurance(memberType: ${memberType}, memberId: "${memberId}") {
                    deductibleType
                    amountApplied
                    inNetworkLabel
                    outNetworkLabel
                     calendarMaxUsed
                     maxRewardsAvailable
                     lifetimeOrthoMaxUsed
                     planMaximums {
                        deductibleType
                        planDesc
                        inNetwork {
                            networkMaximum
                        }
                        outNetwork {
                            networkMaximum
                        }
                     }
                    planDeductibles {
                        coverageType
                        inNetwork {
                            networkDeductible
                        }
                        outNetwork {
                            networkDeductible
                        }
                    }
                    coinsurances {
                        serviceCategory
                        categoryDesc
                        covered
                        inNetwork {
                            networkCoinsurance
                            deductibleApplies
                        }
                        outNetwork {
                            networkCoinsurance
                            deductibleApplies
                        }
                    }
                    coinsuranceClause {
                            label
                            inNetworkValue
                            outNetworkValue
                    }
                    deductibleHelpText
                    coinsuranceHelpText
                    planMaximumHelpText
                }
            }`,
    }, { headers: { authorization: authHeader, 'X-Language-Header': language } })
        .then(response => {
        const deductiblesAndCoinsurance = response?.data?.data?.deductiblesAndCoinsurance;
        let errors = response?.data?.errors;
        return {
            deductiblesAndCoinsurance: deductiblesAndCoinsurance,
            graphQlError: errors && mapError(errors),
        };
    })
        .catch(exception => {
        return { deductiblesAndCoinsurance: undefined, graphQlError: INTERNAL_SERVER_ERROR };
    });
};
export const GetBenefitsAndFrequencies = async (authHeader, language, memberId) => {
    return axios
        .post(dentalGraphqlUrl, {
        query: `
            query benefitsAndFrequencies {
                benefitsAndFrequencies( memberId: "${memberId}") {
                      asOfDate 
                      isFlex
                      preventiveServices {
                        serviceName
                        details
                        additionalInfo
                        frequency
                        ageLimit
                      }
                      basicServices {
                        serviceName
                        details
                        additionalInfo
                        frequency
                        ageLimit
                      }
                      majorServices {
                        serviceName
                        details
                        additionalInfo
                        frequency
                        ageLimit
                      }
                      orthodontia {
                        serviceName
                        details
                        additionalInfo
                        frequency
                        ageLimit
                      } 
                      dentalClauses {
                        label
                        value
                      }
                    }
                    }`,
    }, { headers: { authorization: authHeader, 'X-Language-Header': language } })
        .then(response => {
        const benefitsAndFrequencies = response?.data?.data?.benefitsAndFrequencies;
        const errors = response?.data?.errors;
        return {
            benefitsAndFrequencies: benefitsAndFrequencies,
            graphQlError: errors && mapError(errors),
        };
    })
        .catch(exception => {
        return { benefitsAndFrequencies: undefined, graphQlError: INTERNAL_SERVER_ERROR };
    });
};
export const GetWaitingPeriods = async (authHeader, language, memberType, memberId) => {
    return axios
        .post(dentalGraphqlUrl, {
        query: `
            query waitingPeriods {
                waitingPeriods(memberType: ${memberType}, memberId: "${memberId}") {
                  serviceCategory
                  waitingPeriod {
                    label
                    value
                  }
                  waitingPeriodMet {
                    label
                    value
                  }
                  entrantLimit {
                    label
                    value
                  }
                  entrantLimitMet {
                    label
                    value
                  }          
                  allMet                                                        
                }
            }`,
    }, { headers: { authorization: authHeader, 'X-Language-Header': language } })
        .then(response => {
        const waitingPeriods = response?.data?.data?.waitingPeriods;
        const errors = response?.data?.errors;
        return {
            waitingPeriods: waitingPeriods,
            graphQlError: errors && mapError(errors),
        };
    })
        .catch(exception => {
        return { waitingPeriods: undefined, graphQlError: INTERNAL_SERVER_ERROR };
    });
};
export const GetClaims = async (authHeader, language, memberType, memberId) => {
    return axios
        .post(dentalGraphqlUrl, {
        query: `
            query claims {
                claims(memberType: ${memberType}, memberId: "${memberId}") {
                  claimNumber
                  claimNumberStr
                  status {
                    code
                    value
                  }
                  patientName
                  displayTitle                                                   
                }
            }`,
    }, { headers: { authorization: authHeader, 'X-Language-Header': language } })
        .then(response => {
        const claims = response?.data?.data?.claims;
        const errors = response?.data?.errors;
        return {
            claims: claims,
            graphQlError: errors && mapError(errors),
        };
    })
        .catch(exception => {
        return { claims: undefined, graphQlError: INTERNAL_SERVER_ERROR };
    });
};
export const GetClaimDetails = async (authHeader, language, claimNumber, memberId) => {
    return axios
        .post(dentalGraphqlUrl, {
        query: `
          query claimDetails {
              claimDetails(claimNumber: "${claimNumber}", memberId: "${memberId}") {
                tableHeaders {
                  id
                  value
                }
                claimTreatmentGroups {
                  id
                  treatmentGroupId
                  totalDentistCharge
                  totalCoveredAmount
                  totalDeductible
                  totalCoinsurance
                  cobSecondaryStatement
                  claimTreatments {
                    procedureName
                    procedureCode
                    dentistCharge
                    coveredAmount
                    deductible
                    coinsurance
                    status
                  }
                  paymentStatements
                }
              }
          }`,
    }, { headers: { authorization: authHeader, 'X-Language-Header': language } })
        .then(response => {
        const claimDetails = response?.data?.data?.claimDetails;
        const errors = response?.data?.errors;
        return {
            claimDetails: claimDetails,
            graphQlError: errors && mapError(errors),
        };
    })
        .catch(exception => {
        return { claimDetails: undefined, graphQlError: INTERNAL_SERVER_ERROR };
    });
};
