import useDentalDataContext from 'src/packages/dental/hooks/useDentalContext';
import useTranslate from 'src/hooks/useTranslate';
import { Alert, Col, Spinner } from '@digitools/honest-react';
import DentalText from 'src/packages/dental/constants/DentalText';
import React from 'react';
import PlanOverview from 'src/packages/dental/components/PlanOverview';
import AdditionalEligibilityConditions from 'src/packages/dental/components/AdditionalEligibilityConditions';
import BenefitFrequencyTables from 'src/packages/dental/components/BenefitFrequencyTables';
//TODO This is where the content will go in the subsequent stories, this is a placeholder
export const CoveredServicesInfo = () => {
    const { coveredServicesLoading } = useDentalDataContext();
    if (coveredServicesLoading) {
        return <Spinner data-testid='coveredServices-spinner'/>;
    }
    return (<>
      <PlanOverview />
      <BenefitFrequencyTables />
      <AdditionalEligibilityConditions />
    </>);
};
const CoveredServices = () => {
    const { currentMember, subscriber } = useDentalDataContext();
    const { t } = useTranslate();
    return (<>
      {!!currentMember && !!subscriber && (<Col className={'mt-0 p-0'}>
          <p style={{ paddingTop: '1rem' }}>{t(DentalText.disclaimerText)}</p>
            {currentMember?.planOverview?.inNetworkOnlyClause && (<Alert style={{ padding: '.5rem' }} type={'info'}>{currentMember.planOverview.inNetworkOnlyClause}</Alert>)}
          <PlanOverview />
          <BenefitFrequencyTables />
          <AdditionalEligibilityConditions />
        </Col>)}
    </>);
};
export default CoveredServices;
