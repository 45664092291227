import React from 'react';
import useDentalDataContext from 'src/packages/dental/hooks/useDentalContext';
import styled from 'styled-components';
import { Col, Icon, Row } from '@digitools/honest-react';
import { TEALIUM_EVENT } from 'src/constants/tealiumConstants';
import { getMemberEvent } from 'src/packages/dental/providers/DentalDataProvider';
const DentalButton = ({ image, imageName, component, event, isInlineImageAndTitle, isInline, title, text, hasOutArrow, action, href, eventAction, }) => {
    const { setActiveTab, tealiumFunction, currentMember, subscriber } = useDentalDataContext();
    const memberEvent = getMemberEvent(currentMember, subscriber);
    const ButtonTitle = styled.p `
    margin-top: 0;
    margin-bottom: 0;
    font-weight: 500;
    letter-spacing: 0.15px;
    line-height: 19px;
    font-size: 1rem;
  `;
    const StyledButtonOrLink = styled.div `
    width: 100% !important;
    border: none;
    background-color: transparent;
  `;
    const ButtonOrLinkContainer = styled.div `
    ${({ isInlineImageAndTitle, text, theme }) => `
    background: white;
    box-shadow: 4px 4px 12px rgba(171.57, 171.57, 171.57, 0.15);
    border-radius: 4px;
    border: .5px solid ${theme.palette.grayLighter};
    cursor: pointer;
    width: 100%;
    height: 100%; 
    padding: ${isInlineImageAndTitle && text ? '1rem' : !isInlineImageAndTitle && !text ? '.5rem 0' : '1rem'};
   `}
  `;
    const InnerContent = () => {
        return (<>
        {isInlineImageAndTitle && (<>
            <div className={'d-inline-block w-100 align-content-center pl-0 text-left'} style={{ wordBreak: 'break-word' }}>
              {imageName && (<Icon style={{ float: 'left' }} className={'pr-2'} name={imageName} id={`${imageName}Icon`} size={isInlineImageAndTitle ? 'medium' : 'large'} color={'secondaryLight'}/>)}
              {image && image}
              <ButtonTitle style={{ float: 'left' }} className={'text-secondary m-0'}>
                {title}
              </ButtonTitle>
              {hasOutArrow && (<Icon style={{ float: 'right' }} className={'mt-0'} name={'out-arrow'} id={`${imageName}OutArrow`} size={'medium'} color={'secondaryLight'}/>)}
            </div>
            {text && <p className={'text-left mb-0 mt-0'}>{text}</p>}
          </>)}
        {!isInlineImageAndTitle && (<>
            <div className={'w-100 h-100' +
                    (text
                        ? 'd-inline-block justify-content-between text-left p-0'
                        : 'd-flex align-items-center text-center justify-content-between')} style={{ wordBreak: 'break-word' }}>
              {imageName && (<Icon className={'pr-2'} name={imageName} id={`${imageName}Icon`} size={isInlineImageAndTitle ? 'medium' : 'large'} color={'secondaryLight'}/>)}
              {hasOutArrow && (<Icon style={{ float: 'right' }} name={'out-arrow'} id={`${imageName}OutArrow`} size={'medium'} color={'secondaryLight'}/>)}
              <ButtonTitle className={'text-secondary p-0 mb-0 mt-2 w-100'} style={{ float: 'left' }}>{title}</ButtonTitle>
            </div>
            {text && <p className={'text-left mb-0 p-0'}>{text}</p>}
          </>)}
      </>);
    };
    const ButtonOrLinkTile = () => (<ButtonOrLinkContainer isInlineImageAndTitle={isInlineImageAndTitle} text={text} className={`${!isInlineImageAndTitle && text && 'd-flex flex-column m-0 justify-content-between'}`}>
      <StyledButtonOrLink as={href ? 'a' : 'button'} {...(href
        ? {
            href: href,
            ariaLabelledby: `link to ${title}`,
            rel: 'noreferrer',
            target: '_blank',
        }
        : {
            ariaExpanded: 'true',
            ariaControls: `${component} ? ${imageName}_button : undefined}`,
        })} className={`
        ${!isInlineImageAndTitle && !text
            ? 'align-items-center justify-content-center d-flex w-100 h-100 text-center p-0'
            : !isInlineImageAndTitle && text
                ? 'h-100 p-0 d-flex flex-column justify-content-between m-0'
                : ''}`} onClick={() => {
            if (component) {
                setActiveTab(component);
            }
            tealiumFunction({
                memberEvent: memberEvent,
                eventName: event,
                eventAction: eventAction || (href ? TEALIUM_EVENT.EVENT_ACTION.LINK : TEALIUM_EVENT.EVENT_ACTION.BUTTON),
            });
            if (action) {
                action();
            }
        }}>
        <InnerContent />
      </StyledButtonOrLink>
    </ButtonOrLinkContainer>);
    return isInline ? (<Col>
      <ButtonOrLinkTile />
    </Col>) : (<Row>
      <Col>
        <ButtonOrLinkTile />
      </Col>
    </Row>);
};
export default DentalButton;
